:root {
  --body-bg: #38342c;
  --header-bg: #604322;
  --header-text: #d09f1b;
  --body-text: #c43236;
  --body-header: #ffffff;
  --hover: #c43236;
  --error: #fca160;
}

@media screen and (max-width: 1000px) {
  .split {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
  }
  .outerblock {
    width: 90%;
  }
  .header-block {
    width: 90%;
  }
}
@media screen and (min-width: 1000px) {
  .split {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: 6fr 6fr;
    margin: 15px auto;
  }
  .outerblock {
    width: 70%;
  }
  .header-block {
    width: 70%;
  }
}
.App {
  text-align: center;
  background-color: var(--body-bg);
  color: var(--body-header);
  min-height: 100vh;
  width: 100%;
  font-size: 1.5em;
}
.body {
  min-height: 75vh;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: var(--hover);
}

.App-header {
  background-color: var(--header-bg);
  min-height: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--header-text);
  border-bottom: 1px solid var(--body-header);
}
.header-block {
  display: grid;
  grid-template-columns: 2fr 2fr 6fr 2fr;
  max-width: 1500px;
  align-items: bottom;
  position: relative;
}

.outerblock {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
}

/* Header */
a {
  display: flex;
  color: inherit;
  text-decoration: none;
  align-items: flex-end;
  justify-content: center;
}
h3 {
  justify-content: center;
  font-size: 1.1rem;
  margin: 20px 10px;
}
i {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  margin: 20px 10px;
  color: var(--body-header);
}
/* About */
p {
  text-align: justify;
  color: var(--body-text);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.1rem;
}

/* Projects */
h1 {
  margin: 15px;
  font-size: 2.5rem;
}
h2 {
  margin: 10px;
  font-size: 1.75rem;
}

.half {
  display: inline-block;
  width: 100%;
}
img {
  width: 80%;
  max-width: 500px;
  border: 1px solid #ddd;
  margin-top: 15px;
  /* margin: 5% auto; */
  /* padding: 5px; */
}

.button {
  padding: 10px;
  width: 20%;
  min-width: 80px;
  background-color: #a7cdfb;
  text-decoration: none;
  text-align: center;
  color: #022b3a;
  padding: 10px 20px;
  margin: 15px 8px 20px;
  border: 2px solid #022b3a;
  border-radius: 4px;
  cursor: pointer;
}
.button:hover {
  background-color: var(--hover);
}
/* Contact */

input {
  width: 100%;
  max-width: 750px;
  padding: 12px 20px;
  margin: 8px auto;
  display: block;
  border: 1px solid #ccc;
  background-color: #022b3a;
  color: var(--body-header);
  border-radius: 4px;
  box-sizing: border-box;
}
textarea {
  width: 100%;
  max-width: 750px;
  padding: 12px 20px;
  margin: 8px auto;
  display: block;
  border: 1px solid #ccc;
  background-color: #022b3a;
  color: var(--body-header);
  border-radius: 4px;
  box-sizing: border-box;
  height: 100px;
}
button {
  width: 100%;
  max-width: 750px;
  padding: 12px 20px;
  margin: 8px auto;
  background-color: #a7cdfb;
  color: #022b3a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: var(--body-text);
}
.error {
  color: var(--error);
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
}
.success {
  color: var(--error);
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
}
/* Footer */
.footer {
  position: relative;
  padding: 10px 0px;
  margin: auto;
  left: 0;
  justify-content: center;
  bottom: 0;
  width: 100%;
  color: var(--body-header);
  text-align: center;
}
